import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CatLinks from "../../components/CatLinks"

const Projects = ({ data }) => {
  const textColour = (light) => (light ? '#4e4e4e' : '')
  return (
    <Layout>
      <SEO title="Projects" />
      <section className="projects-collection">
        <div className="container">
          <div className="projects-filter">
            <CatLinks />
          </div>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to={node.fields.slug} key={node.fields.slug + 'project'} >
              <BackgroundImage
                Tag="div"
                className="project-collection--project"
                fluid={node.frontmatter.featureImage.childImageSharp.fluid}
              >
                <span className="hover-colour" style={{ backgroundColor: node.frontmatter.colour }}></span>
                <span className="project-title" style={{ color: textColour(node.frontmatter.light) }} >{node.frontmatter.title}</span>
                <span className="project-category" style={{ color: textColour(node.frontmatter.light) }} >{node.frontmatter.category}</span>
              </BackgroundImage>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___order], order: ASC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            order
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            colour
            light
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Projects
